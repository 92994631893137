<template>
  <v-row justify="space-around">
    <v-menu
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template #activator="{ on, attrs}">
        <v-btn
          outlined
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            color="primary"
            class="mr-4"
          >
            calendar_today
          </v-icon>
          {{ $t('organization.statistics.selectDate') }}
        </v-btn>
      </template>

      <VueCtkDateTimePicker
        v-model="selectedValue"
        class="date-picker"
        :inline="options.inline"
        :range="options.range"
        :format="options.format"
        :color="options.color"
        :min-date="minDate"
        :max-date="maxDate"
        :locale="options.locale"
        :custom-shortcuts="options.shortcuts"
      />
    </v-menu>
  </v-row>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'

export default {
  name: 'DatePicker',
  components: {
    VueCtkDateTimePicker,
  },
  props: {
    category: {
      type: String,
      default: null,
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedValue: null,
    }
  },
  computed: {
    options() {
      return {
        inline: true,
        range: true,
        format: 'YYYY-MM-DD',
        color: '#2acccc',
        locale: this.$i18n.locale,
        shortcuts: [
          { key: 'thisWeek', label: this.$t('organization.statistics.shortcuts.thisWeek'), value: 'isoWeek' },
          { key: 'lastWeek', label: this.$t('organization.statistics.shortcuts.lastWeek'), value: '-isoWeek' },
          { key: 'last7Days', label: this.$t('organization.statistics.shortcuts.last7days'), value: 7 },
          { key: 'last30Days', label: this.$t('organization.statistics.shortcuts.last30days'), value: 30 },
          { key: 'thisMonth', label: this.$t('organization.statistics.shortcuts.thisMonth'), value: 'month' },
          { key: 'lastMonth', label: this.$t('organization.statistics.shortcuts.lastMonth'), value: '-month' },
          { key: 'thisYear', label: this.$t('organization.statistics.shortcuts.thisYear'), value: 'year' },
          { key: 'lastYear', label: this.$t('organization.statistics.shortcuts.lastYear'), value: '-year' },
        ],
      }
    },
  },
  watch: {
    selectedValue() {
      this.$emit(this.category, this.selectedValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.date-picker {
  white-space: nowrap;
}

</style>
